import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosTokenApi } from "../../../utils/axios";
import {
  Button,
  Input,
  Radio,
  Select,
  Spin,
  notification,
  message,
  Upload,
} from "antd";
import {
  ArrowLeftOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import ImageUpload from "../../ImageUpload";

const UpdateSound = () => {
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.info({
      message: `通知`,
      description: "正確に更新されました。",
      placement: "top",
    });
  };

  const RadioGroup = Radio.Group;
  const Id = useParams().id;

  // -------view
  const [categorys, setCategorys] = useState([]);
  const [alltag, setAllTag] = useState([]);
  const [flag, setFlage] = useState(true);
  const [datas, setDatas] = useState([]);

  const [temple, setTemple] = useState("");
  const [order, setOrder] = useState();
  const [category, setCategory] = useState("");
  const [japanesetitle, setJapaneseTitle] = useState("");
  const [englishtitle, setEnglishTitle] = useState("");
  const [japanesedescription, setJapaneseDescription] = useState("");
  const [englishdescription, setEnglishDescription] = useState("");
  const [tags, setTags] = useState();
  const [time, setTime] = useState(0);
  const [sound, setSound] = useState();
  const [ensound, setEnSound] = useState();
  const [file, setFile] = useState();

  const [validTemple, setValidTemple] = useState("");
  const [validOrder, setValidOrder] = useState("");
  const [validCategory, setValidCategory] = useState("");
  const [validJapanesetitle, setValidJapaneseTitle] = useState("");
  const [validEnglishtitle, setValidEnglishTitle] = useState("");
  const [validTags, setValidTags] = useState("");
  const [validTime, setValidTime] = useState("");
  const [validSound, setValidSound] = useState("");
  const [validEnSound, setValidEnSound] = useState("");
  const [validJapaneseDescription, setValidJapaneseDescription] = useState("");
  const [validEnglishDescription, setValidEnglishDescription] = useState("");
  const [validFile, setValidFile] = useState("");

  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    axiosTokenApi
      .get(process.env.REACT_APP_API + "/items/getoneitem", {
        params: {
          id: Id,
        },
      })
      .then((res) => {
        if (res.data.length !== 0) {
          setDatas(res.data);
        }
      });
    axiosTokenApi
      .get(process.env.REACT_APP_API + "/items/getallcategory")
      .then((res) => {
        //  alert(res.data.message);
        setCategorys(res.data);
      });
    axiosTokenApi
      .get(process.env.REACT_APP_API + "/items/getalltags")
      .then((res) => {
        //  alert(res.data.message);
        setAllTag(res.data);
      });
    // setDeleted(false);
    setFlage(false);
  }, [flag]);

  useEffect(() => {
    if (datas.length !== 0 && categorys.length !== 0) {
      setJapaneseTitle(datas.japanesetitle);
      setEnglishTitle(datas.englishtitle);
      setTime(datas.time);
      setTemple(datas.temple);
      setOrder(datas.order);

      // setImagename(datas.main_image_url);
      setJapaneseDescription(datas.japanesedescription);
      setEnglishDescription(datas.englishdescription);
      let temp = [];
      for (let i = 0; i < categorys.length; i++) {
        if (datas.categoryId === categorys[i].id) {
          setCategory(categorys[i].name);
        }
      }
      for (let i = 0; i < datas.tabs.length; i++) {
        temp.push(datas.tabs[i].name);
      }
      setTags(temp);
    }
  }, [datas && categorys]);

  const getFileName = () => {
    var str;
    if (datas) {
      const myArray = datas.main_image_url.split("/");
      str = myArray[myArray.length - 1];
    }
    return str;
  };

  const changeTags = (value) => {
    setTags(value);
  };
  const getSound = (e) => {
    const soundDiv = document.getElementById("sound_div");
    if (soundDiv) {
      soundDiv.style.display = "none";
    }

    setSound(e.target.files[0]);
  };
  const getEnSound = (e) => {
    const ensounDiv = document.getElementById("ensound_div");
    if (ensounDiv) {
      ensounDiv.style.display = "none";
    }
    setEnSound(e.target.files[0]);
  };

  useEffect(() => {
    if (file) {
      const imageDiv = document.getElementById("image_div");
      if (imageDiv) {
        imageDiv.style.display = "none";
      }
    }
  }, [file]);

  const categoryList = () => {
    var str = [];
    for (let i = 0; i < categorys.length; i++) {
      str.push({
        value: categorys[i].name,
        label: categorys.name,
      });
    }
    return str;
  };

  const tagList = () => {
    var str = [];
    for (let i = 0; i < alltag.length; i++) {
      str.push({
        value: alltag[i].name,
        label: `${alltag[i].name}(${alltag[i].en_name})`,
      });
    }
    return str;
  };

  const handleAdd = async () => {
    let updated = false;
    let isValidate = true;

    // お寺の名前
    if (!temple) {
      setValidTemple("お寺の名前を入力してください。");
      isValidate = false;
    } else if (temple.length > 100) {
      setValidTemple("お寺の名前は100文字以内で入力してください。");
      isValidate = false;
    } else {
      setValidTemple("");
    }

    // ホーム画面の並び順
    if (!order) {
      setValidOrder("ホーム画面の並び順を入力してください。");
      isValidate = false;
    } else {
      setValidOrder("");
    }

    // カテゴリ
    if (!category) {
      setValidCategory("カテゴリを選択してください。");
      isValidate = false;
    } else {
      setValidCategory("");
    }

    // 音源タイトル（日）
    if (!japanesetitle) {
      setValidJapaneseTitle("動画タイトル(日)を入力してください。");
      isValidate = false;
    } else if (japanesetitle.length > 100) {
      setValidJapaneseTitle(
        "動画タイトル(日)は100文字以内で入力してください。"
      );
      isValidate = false;
    } else {
      setValidJapaneseTitle("");
    }

    // 音源タイトル（英）
    if (englishtitle.length && englishtitle.length > 100) {
      setValidEnglishTitle("動画タイトル(英)は100文字以内で入力してください。");
      isValidate = false;
    } else {
      setValidEnglishTitle("");
    }

    // 説明（日）
    if (!japanesedescription) {
      setValidJapaneseDescription("説明(日)を入力してください。");
      isValidate = false;
    } else if (japanesedescription.length > 5000) {
      setValidJapaneseDescription("説明(日)は5000文字以内で入力してください。");
      isValidate = false;
    } else {
      setValidJapaneseDescription("");
    }

    // 説明（英）
    if (englishdescription.length && englishdescription.length > 5000) {
      setValidEnglishDescription("説明(英)は5000文字以内で入力してください。");
      isValidate = false;
    } else {
      setValidEnglishDescription("");
    }

    // タグ
    if (!tags) {
      setValidTags("タグを選択してください。");
      isValidate = false;
    } else {
      setValidTags("");
    }

    // 再生時間
    if (!time) {
      setValidTime("再生時間を入力してください。");
      isValidate = false;
    } else {
      setValidTime("");
    }

    // 音源ファイル
    if (sound) {
      if (
        sound.type === "audio/mpeg" ||
        sound.type === "audio/mp3" ||
        sound.type === "audio/wav" ||
        sound.type === "audio/ogg"
      ) {
        setValidSound("");
      } else {
        setValidSound(
          "アップロードできる音源ファイルはmpeg/mp3/wav/oggのみです！"
        );
        isValidate = false;
      }
    }
    if (ensound) {
      if (
        ensound.type === "audio/mpeg" ||
        ensound.type === "audio/mp3" ||
        ensound.type === "audio/wav" ||
        ensound.type === "audio/ogg"
      ) {
        setValidEnSound("");
      } else {
        setValidEnSound(
          "アップロードできる音源ファイルはmpeg/mp3/wav/oggのみです！"
        );
        isValidate = false;
      }
    }

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        setValidFile("");
      } else {
        setValidFile("アップロードできる画像ファイルはjpeg/png/jpegのみです！");
        isValidate = false;
      }
    }

    if (!isValidate) {
      return;
    }

    setSpinning(true);

    const data = {
      id: Id,
      temple: temple,
      order: order,
      time: time,
      tags: tags,
      category: category,
      japanesetitle: japanesetitle,
      englishtitle: englishtitle,
      japanesedescription: japanesedescription,
      englishdescription: englishdescription,
    };
    if (sound) {
      data.soundname = sound.name;
    }
    if (ensound) {
      data.en_soundname = ensound.name;
    }
    if (file) {
      data.filename = file.name;
    }
    const response = await axiosTokenApi.post(
      process.env.REACT_APP_API + "/items/updateitem_sound",
      data
    );
    if (response.status === 200) {
      updated = true;
    }

    if (updated) {
      if (sound) {
        const formData1 = new FormData();
        formData1.append("sound", sound);
        formData1.append("itemId", Id);
        const config1 = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        await axiosTokenApi
          .post(
            process.env.REACT_APP_API + "/item/addsound",
            formData1,
            config1
          )
          .then((response) => {
            console.log("success");
          })
          .catch((error) => {
            console.error("Error uploading files: ", error);
          });
      }

      if (ensound) {
        const formData2 = new FormData();
        formData2.append("sound", ensound);
        formData2.append("itemId", Id);
        const config2 = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        await axiosTokenApi
          .post(
            process.env.REACT_APP_API + "/item/addensound",
            formData2,
            config2
          )
          .then((response) => {
            console.log("success");
          })
          .catch((error) => {
            console.error("Error uploading files: ", error);
          });
      }
    }
    if (file && updated) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("itemId", Id);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await axiosTokenApi
        .post(process.env.REACT_APP_API + "/item/addimage", formData, config)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error uploading files: ", error);
        });
    }

    openNotification();
    setSpinning(false);
    setTimeout(() => {
      navigate("/manage/sound");
    }, 1000);
  };

  // ----------add
  return (
    <>
      {contextHolder}
      <Spin spinning={spinning} fullscreen />
      <div className="p-10 bg-white shadow-md mx-auto my-3 overflow-y-auto main">
        <div className="w-full h-10 mb-2">
          <p className="text-2xl text-left mx-10 font-bold">音源編集</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              音源ID
            </div>
            <Input type="text" value={Id} disabled className="w-3/4" />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              お寺
            </div>
            <Input
              type="text"
              value={temple}
              onChange={(e) => setTemple(e.target.value)}
              className="w-3/4"
            />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validTemple}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              ホーム画面の並び順
            </div>
            <Input
              type="number"
              min={1}
              max={30}
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              className="w-1/4"
            />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validOrder}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              カテゴリ
            </div>
            <div className="w-3/4 text-left py-2">
              <RadioGroup
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categoryList().map((data, index) => {
                  return (
                    <Radio key={index} value={data.value}>
                      {data.value}
                    </Radio>
                  );
                })}
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validCategory}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              音源タイトル（日）
            </div>
            <Input
              type="text"
              value={japanesetitle}
              onChange={(e) => setJapaneseTitle(e.target.value)}
              className="w-3/4"
            />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validJapanesetitle}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              音源タイトル（英）
            </div>
            <Input
              type="text"
              value={englishtitle}
              onChange={(e) => setEnglishTitle(e.target.value)}
              className="w-3/4"
            />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validEnglishtitle}</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full h-20 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              説明（日）
            </div>
            <TextArea
              value={japanesedescription}
              onChange={(e) => setJapaneseDescription(e.target.value)}
              className="w-3/4 h-20"
            />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validJapaneseDescription}</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full h-20 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              説明（英）
            </div>
            <TextArea
              value={englishdescription}
              onChange={(e) => setEnglishDescription(e.target.value)}
              className="w-3/4 h-20"
            />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validEnglishDescription}</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              タグ
            </div>
            <Select
              className="w-3/4"
              mode="multiple"
              value={tags}
              onChange={(value) => {
                changeTags(value);
              }}
              options={tagList()}
            />
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validTags}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              再生時間
            </div>
            <Input
              type="number"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="w-40"
            />
            <p className="text-sm m-2">分</p>
          </div>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validTime}</p>
        </div>
        <div className="w-full mb-1">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              音源ファイルアップロード（日）
            </div>
            <div className="w-1/4 text-left text-sm font-medium py-2">
              <Input type="file" onChange={getSound} />
            </div>
          </div>
          {datas.filename && (
            <div id="sound_div" className="w-full h-16 mt-1 px-10 flex">
              <div className="w-1/4 text-center text-sm font-medium py-2"></div>
              <p className="m-3">{datas.filename}</p>
            </div>
          )}
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validSound}</p>
        </div>

        <div className="w-full mb-1">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              音源ファイルアップロード（英）
            </div>
            <div className="w-1/4 text-left text-sm font-medium py-2">
              <Input type="file" onChange={getEnSound} />
            </div>
          </div>
          {datas.en_filename && (
            <div id="ensound_div" className="w-full h-16 mt-1 px-10 flex">
              <div className="w-1/4 text-center text-sm font-medium py-2"></div>
              <p className="m-3">{datas.en_filename}</p>
            </div>
          )}
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validEnSound}</p>
        </div>

        <div className="w-full mb-2">
          <div className="w-full px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">
              サムネイル
            </div>
            <div className="w-1/2 text-left text-sm font-medium py-2">
              <ImageUpload file={file} setFile={setFile} />
            </div>
          </div>
          {datas.main_image_url && (
            <div id="image_div" className="w-full h-16 mt-8 px-10 flex">
              <div className="w-1/4 text-center text-sm font-medium py-2"></div>
              <div className="w-1/2 flex text-sm font-medium border rounded p-2">
                <img
                  src={process.env.REACT_APP_SERVER + datas.main_image_url}
                  className="w-12"
                />
                <p className="m-3">{getFileName()}</p>
              </div>
            </div>
          )}
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-1/4 text-center text-sm font-medium py-2"></div>
          <p className="mx-6 text-red-500">{validFile}</p>
        </div>
        <div className="w-full text-center mt-24 flex">
          <div className="w-1/4 text-center">
            <Link to="/manage/sound">
              <Button className="w-48 m-auto">
                <ArrowLeftOutlined /> 戻 る
              </Button>
            </Link>
          </div>
          <div className="w-3/4 text-center">
            <Button
              type="primary"
              onClick={handleAdd}
              className="w-80 text-black border-gray-300"
            >
              <div className="flex mx-28">
                <SaveOutlined className="mr-2" />登 録
              </div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateSound;
